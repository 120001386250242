import StyledImage from '@/components/styledImage';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Divider, ListItemButton, ListItemIcon, ListItemText, Radio, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { Order } from '../types/schema';
import { QBTaxesButton } from './qbInvoiceTax';

export default function SyncWithHouseAccount( {
	onSubmit,
	invoice,
	selectQBTax,
	isView,
	onlyHouseAccountOptions = false,
}: {
	onSubmit: ( action: string, qbTax?: any ) => void,
	invoice: Order,
	selectQBTax?: boolean,
	isView?: boolean,
	onlyHouseAccountOptions?: boolean
} ) {
	const { closeModal } = useModalControls();
	const [ tax, setTax ] = useState( null );
	const [ actionType, setActionType ] = useState( '' );
	
	const amount = ( invoice.grandTotal || 0 ) - ( invoice.paidTotal || 0 );
	
	return (
		<ResponsiveModalContainer
			title='Your client has a house account'
			secondaryTitle='Should it be a house account order instead?'
			saveButtonText={isView ? 'View' : 'Sync & view'}
			saveButtonProps={{
				disabled : !actionType,
				startIcon: isView ? undefined : (
					<StyledImage
						alt='clover-pos-image'
						src='/images/clover-icon.png'
						width='20px'
						height='20px'
					/>
				),
			}}
			onClose={() => {
				setActionType( '' );
				setTax( null );
				closeModal();
			}}
			onSave={() => {
				onSubmit( actionType, tax );
				closeModal();
			}}>
			<Stack spacing={1}>
				{!onlyHouseAccountOptions && (
					<ListItemButton
						selected={actionType === 'Sync'}
						onClick={() => setActionType( 'Sync' )}>
						<ListItemIcon>
							<Radio checked={actionType === 'Sync'}/>
						</ListItemIcon>
						<ListItemText
							primary='Individual Invoice'
							secondary='Do not charge to the House Account. My client will pay individually.'
						/>
					</ListItemButton>
				)}
				{!onlyHouseAccountOptions && ( <Divider sx={{ my: 1 }}/> )}
				{!isView && (
					<ListItemButton
						selected={actionType === 'Pay with Invoiss'}
						disabled={amount === 0}
						onClick={() => setActionType( 'Pay with Invoiss' )}>
						<ListItemIcon>
							<Radio checked={actionType === 'Pay with Invoiss'}/>
						</ListItemIcon>
						<ListItemText
							primary='Charge to House Account'
							secondary='Mark it as paid on Clover (Recommended)'
						/>
					</ListItemButton>
				)}
				<ListItemButton
					disabled={amount === 0}
					selected={actionType === 'Pay without Invoiss'}
					onClick={() => setActionType( 'Pay without Invoiss' )}>
					<ListItemIcon>
						<Radio checked={actionType === 'Pay without Invoiss'}/>
					</ListItemIcon>
					<ListItemText
						primary='Charge to House Account'
						secondary={!isView ? 'Leave it open on Clover' : undefined}
					/>
				</ListItemButton>
				{!isView && selectQBTax && !invoice.client?.metadata?.exemptFromTax && (
					<Fragment>
						<Divider sx={{ my: '20px !important' }}/>
						<Typography variant='h6'>
							QuickBooks Taxes
						</Typography>
						<QBTaxesButton tax={tax} setTax={( tax ) => setTax( tax )}/>
					</Fragment>
				)}
			</Stack>
		</ResponsiveModalContainer>
	);
}
