import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommerceWrite } from '@/data/commerce/commerce.graphql';
import { EstimateWrite } from '@/data/commerce/estimate.graphql';
import { EstimateRead_ApproveToActionQuery, EstimateRead_ApproveToActionQueryVariables } from '@/generated/graphql';
import { commercePropertiesToOmit } from '@/pages/dashboard/commerce/invoices/actions/invoiceUtils';
import {
	CommerceType,
	MutationCommerceWriteArgs,
	MutationEstimateWriteArgs,
	Order,
	Staff,
} from '@/types/schema';
import { gql } from '@apollo/client';
import { addDays } from 'date-fns';
import { omit, pick, toLower } from 'lodash-es';

export async function approveToAction(
	estimate: Order,
	staff: Staff,
	option: string,
	keepNumber: boolean = false,
	customCreatedAt: Date | null | undefined,
	type: CommerceType,
	router: any,
	enqueueSnackbar: any,
	t: any ) {
	const toInvoice = type === 'INVOICE';
	const loweredType = toLower( type );
	if ( option === 'approve' ) {
		try {
			const { estimateRead } = await queryGraphQL<EstimateRead_ApproveToActionQueryVariables, EstimateRead_ApproveToActionQuery>( {
				query: gql`
					query EstimateRead_ApproveToAction($id: String!) {
						estimateRead(id: $id) {
							lineItems {
								id
								name
								price
								image
								unit
								quantity
								tax
								sequence
								metadata
								externalId
								orderTax
								description
								modifierGroups {
									id
								}
								prices {
									name
									isPercent
									value
									quantity
									metadata
									externalId
								}
								uom {
									id
								}
								item {
									id
								}
								category {
									id
								}
							}
							agreements {
								title
								body
								requireSignature
								expiration
								company {
									id
								}
							}
							prices {
								name
								isPercent
								value
								quantity
								metadata
								externalId
							}
							scheduledPayments {
								percent
								reason
								dueDate
							}
						}
					}
				`,
				variables: { id: estimate.id },
			} );
			
			const lineItems = estimateRead.lineItems;
			const agreements = estimateRead.agreements;
			const prices = estimateRead.prices;
			const scheduledPayments = estimateRead.scheduledPayments;
			
			const { commerceWrite } = await mutateGraphQL<MutationCommerceWriteArgs>( {
				mutation : CommerceWrite,
				variables: {
					id          : null,
					customNumber: keepNumber ? undefined : Boolean( staff.company.metadata?.customNumber ),
					method      : toInvoice ? 'Invoice from Estimate' : 'Order from Estimate',
					customCreatedAt,
					input       : {
						type,
						...pick( estimate, [
							'notes',
							'po',
							'terms',
							'standing',
							'standingDue',
							'standingData',
							'duePeriod',
							'serviceType',
							'attachments',
							'dateSent',
							'taxPercent',
							'policy' ] ),
						number: keepNumber
							? estimate.metadata?.customNumber || estimate.number
							: undefined,
						standingDate   : new Date(),
						dueDate        : addDays( new Date(), estimate.company?.metadata?.dueDate || estimate.company?.metadata?.serviceDateDue || 0 ),
						serviceDate    : new Date(),
						staff          : estimate.staff?.id,
						client         : estimate.client?.id || null,
						companyLocation: estimate.companyLocation?.id || null,
						shippingAddress: estimate.shippingAddress?.id || null,
						clientAddress  : estimate.clientAddress?.id || null,
						policy         : estimate.policy?.id || null,
						metadata       : {
							...omit( estimate.metadata, commercePropertiesToOmit ),
							sendReminder      : staff.company.metadata?.dueReminder,
							enableCashDiscount: estimate.metadata?.enableCashDiscount && estimate.company.metadata?.cashDiscount > 0,
							signatureLine     : staff.company.metadata?.signatureLine,
							customNumber      : estimate.metadata?.customNumber || undefined,
						},
						prices: ( prices || [] ).filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
							.map( ( price ) => pick( price, [
								'name',
								'isPercent',
								'value',
								'quantity',
								'metadata',
								'externalId',
							] ) ),
						scheduledPayments: scheduledPayments?.map( ( payment ) => ( {
							...pick( payment, [ 'percent', 'reason', 'dueDate' ] ),
							staff  : estimate?.staff?.id || staff?.id,
							client : estimate?.client?.id || null,
							company: estimate?.company.id,
						} ) ),
						agreements: agreements?.map( ( agreement ) => ( {
							...pick( agreement, [
								'title',
								'body',
								'requireSignature',
								'expiration',
							] ),
							company: staff?.company?.id || estimate?.company?.id,
						} ) ),
						lineItems: lineItems?.map( ( lineItem ) => ( {
							...pick( lineItem, [
								'name',
								'price',
								'image',
								'unit',
								'quantity',
								'tax',
								'sequence',
								'metadata',
								'externalId',
								'orderTax',
							] ),
							modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
							description   : `${lineItem.description || ''}`,
							prices        : lineItem.prices?.map( ( price ) =>
								pick( price, [
									'name',
									'isPercent',
									'value',
									'quantity',
									'metadata',
									'externalId',
								] ) ),
							uom     : lineItem.uom?.id || null,
							item    : lineItem.item?.id || null,
							category: lineItem.category?.id || null,
						} ) ),
					},
				},
			} );
			
			await mutateGraphQL<MutationEstimateWriteArgs>( {
				mutation : EstimateWrite,
				variables: {
					id    : estimate.id,
					method: toInvoice ? 'Invoiced' : 'Ordered',
					input : toInvoice ? { invoiced: true, sent: true } : { ordered: true, sent: true },
				},
			} );
			enqueueSnackbar( `Great. You will have a drafted ${loweredType} ready to adjust.` );
			await router.push( `/dashboard/commerce/${loweredType}s/${commerceWrite.id}/edit` );
		} catch ( e ) {
			console.error( e );
			throw e;
		}
	} else if ( option === 'move' ) {
		try {
			await mutateGraphQL<MutationCommerceWriteArgs>( {
				mutation : CommerceWrite,
				variables: {
					id    : estimate.id,
					method: toInvoice ? 'Moved to Invoice' : 'Moved to Order',
					input : {
						type,
						staff    : staff.id,
						sent     : false,
						merged   : false,
						completed: false,
						invoiced : false,
						ordered  : false,
						paid     : false,
						cancelled: false,
						standing : false,
						viewed   : false,
						metadata : {
							cashDiscount      : false,
							stock             : false,
							enableCashDiscount: estimate.metadata?.enableCashDiscount && estimate.company.metadata?.cashDiscount > 0,
						},
					},
				},
			} );
			await router.push( `/dashboard/commerce/${loweredType}s/${estimate.id}/edit` );
			enqueueSnackbar( t( `commerce:estimate-will-be-drafted-${loweredType}` ) );
		} catch ( e ) {
			throw e;
		}
	}
}
