import Form from '@/components/form';
import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import TextFieldInputLabel from '@/components/form/inputLabel';
import { ModalFormWrapper } from '@/components/form/modal';
import currencyFormat from '@/helpers/currencyFormat';
import { getOrderTotalDiscountAndFees } from '@/pages/dashboard/commerce/payment/helpers';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Device, Order } from '@/types/schema';
import { Box, InputAdornment, ListItem, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { round } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TerminalDevicePaymentModal( { invoice, devices }: { invoice: Order, devices: Device[] } ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	
	const remainingAmount = invoice.grandTotal - ( invoice.paidTotal || 0 );
	const cardFeePercentage = invoice.company.metadata?.cardFee || 0;
	const cardFee = round( invoice.company.metadata?.cardFee || 0, 2 ) / 100;
	const grandTotal = invoice.grandTotal || 0;
	const subTotal = invoice.subTotal || 0;
	const taxTotal = invoice.taxTotal || 0;
	const remaining = grandTotal - ( invoice.paidTotal || 0 );
	const paidCardFee = invoice.payments?.reduce( ( sum,
		payment ) => sum.plus( payment?.fee || 0 ), new BigNumber( 0 ) ) || 0;
	
	const totals = getOrderTotalDiscountAndFees( invoice );
	const totalDiscount = new BigNumber( totals.totalDiscount );
	const totalFees = new BigNumber( totals.totalFees );
	const additionalPrices = totalDiscount.plus( totalFees.minus( paidCardFee || 0 ) ).toNumber();
	const totalBeforeTax = new BigNumber( subTotal ).plus( additionalPrices );
	let cardFeeAmount = 0;
	let finalPaying = 0;
	
	return (
		<Form<{ amountType: string, cardType: string, amount: number, textAmount: number, device: string }>
			initialValues={{
				amountType: 'fullAmount',
				cardType  : 'credit',
				textAmount: remainingAmount,
				amount    : remainingAmount,
				device    : devices[ 0 ].id,
			}}
			onSubmit={async ( values ) => {
				const selectedDevice = devices.find( ( d ) => d.id === values?.device );
				if ( !selectedDevice ) {
					enqueueSnackbar( 'Error selecting device!', { variant: 'error' } );
					return;
				}
				enqueueSnackbar( 'Sending payment request to device...', { variant: 'info' } );
				const { data } = await axios.post( '/api/processor/cardConnect/terminal/sendPayment', {
					merchantId    : selectedDevice?.gateway?.externalKey,
					amount        : round( finalPaying, 2 ),
					hsn           : selectedDevice.serial,
					terminalApiKey: selectedDevice?.gateway?.terminalApiKey || selectedDevice?.apiKey,
					orderId       : invoice.id,
					cardType      : values.cardType,
					gatewayId     : selectedDevice?.gateway?.id,
					clientId      : invoice?.client?.id || null,
					paidTotal     : invoice.paidTotal,
					grandTotal    : invoice.grandTotal,
					staffId       : staff?.id,
					companyId     : invoice.company.id,
					metadata      : staff?.company?.metadata,
					cardFeeAmount : values.cardType === 'credit' ? cardFeeAmount : 0,
				} );
				
				if ( data?.result?.status === 'A' ) {
					enqueueSnackbar( 'Payment recorded successfully!', { variant: 'success' } );
				} else if ( data?.result?.status === 'C' ) {
					enqueueSnackbar( 'Payment declined!', { variant: 'error' } );
				} else if ( !data?.result ) {
					enqueueSnackbar( 'Error sending payment to device! Please make sure the device is properly set up and connected!', { variant: 'error' } );
				}
				if ( !data?.updated && invoice.company?.metadata?.stockMethod === 'manualPaymentStock' ) {
					enqueueSnackbar( 'Stock could not be updated!', { variant: 'error' } );
				}
			}}>
			{( formik ) => {
				const selectedDevice = devices.find( ( d ) => d.id === formik.values.device );
				
				const amountToPay = formik.values.amount;
				const paying = Math.min( remaining, amountToPay );
				
				const payingBeforeTax = new BigNumber( paying ).minus( new BigNumber( taxTotal )
					.times( paying )
					.div( totalBeforeTax.plus( taxTotal ) ) );
				
				cardFeeAmount = formik.values.cardType === 'credit' ? payingBeforeTax.times( cardFee )
					.decimalPlaces( 2 )
					.toNumber() : 0;
				finalPaying = new BigNumber( paying ).plus( cardFeeAmount ).decimalPlaces( 2 ).toNumber();
				
				return (
					<ModalFormWrapper
						title='Payment Amount'
						saveButtonProps={{ disabled: !remainingAmount }}
						saveButtonText='Pay'>
						<Stack spacing={1}>
							{devices.length > 1 ? (
								<FormSelect
									fullWidth
									name='device'
									label='Select Device'
									size='small'>
									{devices.map( ( device ) => (
										<MenuItem key={device.id} value={device.id}>
											{device.productName || device.label || device.serial}
										</MenuItem>
									) )}
								</FormSelect>
							) : (
								<Typography variant='h6'>Device: {selectedDevice.productName || selectedDevice.label || selectedDevice.serial}</Typography>
							)}
							{formik.values.device && (
								<Box>
									<Stack direction='row' alignItems='center' spacing={1}>
										<FormSelect
											name='amountType'
											sx={{ width: 100 }}
											size='small'
											onChange={( e ) => {
												formik.setValues( {
													...formik.values,
													amountType: e.target.value,
													amount:
														e.target.value === 'fullAmount'
															? remainingAmount
															: formik.values.amount,
													textAmount: e.target.value === 'fullAmount'
														? remainingAmount
														: formik.values.amount,
												} );
											}}>
											<MenuItem value='fullAmount'>{t( 'commerce:full' )}</MenuItem>
											<MenuItem value='dollarAmount'>$</MenuItem>
											<MenuItem value='percentAmount'>%</MenuItem>
										</FormSelect>
										<FormTextField
											fullWidth
											disabled={formik.values.amountType === 'fullAmount'}
											type='number'
											name='textAmount'
											placeholder={t( 'common:amount' )}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{formik.values.amountType === 'percentAmount' ? '%' : '$'}
													</InputAdornment>
												),
											}}
											onFocus={( e ) => e.target.select()}
											onChange={( e ) => {
												const value = +e.target.value;
												let amount = 0;
												let percent = 0;
												switch ( formik.values.amountType ) {
													case 'dollarAmount':
														amount = value > remainingAmount ? remainingAmount : value;
														break;
													case 'percentAmount':
														percent = value > 100 ? 100 : value < 0 ? 0 : value;
														amount = percent === 100
															? remainingAmount
															: remainingAmount * percent / 100;
														break;
													default:
														// full amount
														amount = remainingAmount;
												}
												formik.setFieldValue( 'textAmount', percent || amount );
												formik.setFieldValue( 'amount', amount );
											}}
										/>
									</Stack>
									<Box my={1} sx={{ minWidth: 300 }}>
										<TextFieldInputLabel label='Card Type'/>
										<FormSelect fullWidth name='cardType'>
											<MenuItem value='credit'>
												{cardFeePercentage && cardFeePercentage !== 0
													? `Credit card (${round( cardFeePercentage, 2 )}% Charge)`
													: 'Card'}
											</MenuItem>
											{cardFeePercentage && (
												<MenuItem value='debit'>
													Debit Card
												</MenuItem>
											)}
										</FormSelect>
									</Box>
									<Box>
										<ListItem disableGutters>
											<ListItemText primary='Card Fee'/>
											<Typography style={{ fontSize: 18 }}>
												{currencyFormat( cardFeeAmount )}
											</Typography>
										</ListItem>
										<ListItem disableGutters>
											<ListItemText primary={t( 'common:total' )}/>
											<Typography>
												{currencyFormat( remainingAmount )}
											</Typography>
										</ListItem>
										<ListItem disableGutters>
											<ListItemText primary={t( 'common:paying' )}/>
											<Typography variant='h3'>
												{currencyFormat( finalPaying )}
											</Typography>
										</ListItem>
									</Box>
								</Box>
							)}
						</Stack>
					</ModalFormWrapper>
				);
			}}
		</Form>
	);
}
